'use client';

import getDashboardLinkAction from '@/actions/signin/getDashboardLinkAction';
import { useRouter } from 'next/navigation';
import AnimatedButton from '../animated/AnimatedButton';

export default function GetStartedButton({ children }: { children: any }) {
  const router = useRouter();
  return (
    <AnimatedButton
      className="text-md flourish-background"
      shouldReset={false}
      onClick={() => getDashboardLinkAction().then((url) => router.push(url))}
    >
      {children}
    </AnimatedButton>
  );
}
